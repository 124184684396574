import React, { useContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { authRoutes, publicRoutes } from "../routes";
import Home from "../pages/Home";
import { Context } from "..";
import { observer } from "mobx-react-lite";

const useScrollRestoration = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0); // Прокручивает вверх при смене маршрута
	}, [pathname]);
};

const AppRouter = observer(() => {
	const { user } = useContext(Context);
	useScrollRestoration();

	return (
		<Routes>
			{user.isAuth &&
				authRoutes.map(({ path, Component }) => (
					<Route
						key={path}
						path={path}
						element={Component}
						exact
					/>
				))}
			{publicRoutes.map(({ path, Component }) => (
				<Route
					key={path}
					path={path}
					element={Component}
					exact
				/>
			))}
			<Route
				path='*'
				element={<Home />}
			/>
		</Routes>
	);
});

export default AppRouter;
