import { useState, useEffect, useRef } from "react";
import Calendar from "../../components/Calendar";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchOneCommitteeMeetings } from "../../http/NewsAPI";
import parse from "html-react-parser";
import ShareButton from "../../components/UI/ShareButton";

const CommitteeMeetingsPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
			window.location.reload();
		};
	};

	useEffect(() => {
		fetchOneCommitteeMeetings(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				{data ? (
					<div
						className='d-flex flex-column col-xl-8 px-xl-4'
						ref={printRef}>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-column justify-content-center pb-4'>
							{data.img ? (
								<Image
									className='rounded'
									style={{
										maxHeight: "50vh",
										width: "auto",
										maxWidth: "100%",
										aspectRatio: 1,
										objectFit: "contain",
									}}
									src={process.env.REACT_APP_API_URL + data.img}
								/>
							) : null}
							{data.article ? <span className='text-justify font-2 pb-2'>{parse(data.article)}</span> : null}
							{data.link ? (
								<a
									href={data.link}
									className='text-justify font-2 pb-2'>
									Link către resurs
								</a>
							) : null}
						</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end'>
							<div className='d-flex flex-row align-items-center justify-content-end'>
								<i
									role='button'
									onClick={() => handlePrint()}
									className='bi bi-printer text-primary icon-3'
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default CommitteeMeetingsPage;
