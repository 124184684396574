import { useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { fetchKeySectors } from "../http/resourcesServicesAPI";
import { Spinner } from "react-bootstrap";
import parse from "html-react-parser";
import ShareButton from "../components/UI/ShareButton";

const KeySectors = () => {
	const [sector, setSector] = useState();
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
			window.location.reload();
		};
	};

	useEffect(() => {
		fetchKeySectors()
			.then((data) => {
				setSector(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div
					className='d-flex flex-column col-xl-8 col-12 px-xl-4'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Sectoare Cheie</span>
					</div>
					{sector.count > 0
						? sector.rows.map((item, idx) => (
								<div
									key={idx}
									className='d-flex flex-column justify-content-center'>
									<Image
										src={process.env.REACT_APP_API_URL + item.img}
										style={{
											maxHeight: "50vh",
											width: "auto",
											maxWidth: "100%",
											aspectRatio: 1,
											objectFit: "contain",
										}}
									/>
									{item.description ? <span className='text-justify text-wrap'>{parse(item.description)}</span> : null}
								</div>
						  ))
						: null}
					{sector.count > 0 ? (
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100'>
							<span className='border border-primary rounded text-primary my-2 my-xl-0 py-1 px-2 fw-bold'>
								{sector.rows[sector.rows.length - 1].author}
							</span>
							<span className='border border-primary rounded text-primary py-1 px-2 fw-bold'>
								{sector.rows[sector.rows.length - 1].date}
							</span>

							<div className='d-flex flex-row align-items-center justify-content-end ps-1 pe-5'>
								<i
									role='button'
									onClick={() => handlePrint()}
									className='bi bi-printer text-primary icon-3'
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<ShareButton />
							</div>
						</div>
					) : null}
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default KeySectors;
